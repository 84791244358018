import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { RiDoubleQuotesR } from "react-icons/ri";

const CallToActionThree = ({ slides }) => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  return (
    <section className="cta-area cta-area2 text-center">
      <Swiper
        onSwiper={setSwiper}
        modules={[Autoplay]}
        autoplay={{ delay: 6000 }}
      >
        {slides.map((slide) => (
          <SwiperSlide className="single-slide-item slide-bg4" key={uuidv4()}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 mx-auto">
                  <div className="cta-item">
                    <h3 className="cta__quote">
                      <RiDoubleQuotesR />
                    </h3>
                    <h3 className="cta__title">{slide.title}</h3>
                    <p className="cta__desc">
                      {slide.meta} | {slide.date}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default CallToActionThree;
