import React from "react";
import styled from "styled-components";

const StyledButton = styled.a`
  position: fixed !important;
  z-index: 111 !important;
  bottom: 25px;
  right: 25px;
  /* cursor: pointer; */
  /* background-color: var(--highlight-color2); */
  color: var(--white);
  padding: 15px 25px;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 1px;
  width: 200px;

  &:hover {
    background-color: var(--highlight-color3);
  }
  @media screen and (min-width: 768px) {
    width: 200px;
  }
`;

const DonateButton = ({ link }) => (
  <StyledButton
    className="theme-btn theme-btn-2 theme-btn-main"
    href={link}
    alt="Donate now"
  >
    Donate Now
  </StyledButton>
);

export default DonateButton;
