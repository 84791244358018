import React from "react";
import { IoPeopleSharp } from "react-icons/io5";
import TeamMember from "./TeamMember";
import team1 from "../images/board_kati_marton.jpg";
import team2 from "../images/board_david_koranyi.jpg";
import team3 from "../images/board_chris_maroshegyi.png";
import team4 from "../images/board_elisabeth.jpg";
import team5 from "../images/board_applebaum.jpg";
import team6 from "../images/board_alHussein.jpg";
import team7 from "../images/board_boorstin.jpg";
import team8 from "../images/board_gartonAsh.jpg";
import team9 from "../images/board_snyder.jpg";
import team10 from "../images/board_clark.jpg";
import team11 from "../images/board_gati.jpg";
import team12 from "../images/board_fukuyama.jpg";
import team13 from "../images/board_farkas.jpg";
import team14 from "../images/board_kounalakis.jpg";
import team15 from "../images/board_cheng.jpg";
import team16 from "../images/board_rafa.jpg";
import team17 from "../images/board_milliband.jpg";

const TeamArea = () => {
  return (
    <section className="team-area text-center" id="council">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="section-heading">
              <div className="section-icon">
                <IoPeopleSharp />
              </div>
              <h2 className="section__title">Advisory Council Members</h2>
            </div>
          </div>
        </div>
        <div className="row team-content-wrap justify-content-center">
          <TeamMember
            name={"Kati Marton"}
            title={"Advisory Council Chair"}
            image={team1}
            text={""}
            // text={
            //   "Hungarian born Kati Marton has combined a career as a writer with human rights advocacy. Author of ten books,  Marton also chaired the International Women’s Health Coalition, was Chief Advocate for the UN’s Office for Children and Armed Conflict, a trustee of Human Rights Watch, and chair of the Committee to Protect Journalists. A former ABC News correspondent and Bureau Chief in Germany, Marton started her journalism career at NPR."
            // }
            facebook={"https://www.facebook.com/kati.marton.94"}
            twitter={"https://twitter.com/katimarton"}
          />
          <TeamMember
            name={"Timothy Garton Ash"}
            title={"Advisory Council Member"}
            image={team8}
            text={""}
            // text={
            //   "Timothy Garton Ash is the author of ten books of political writing or ‘history of the present’ which have charted the transformation of Europe over the last half century. He is Professor of European Studies in the University of Oxford, Isaiah Berlin Professorial Fellow at St Antony’s College, Oxford, and a Senior Fellow at the Hoover Institution, Stanford University. His essays appear regularly in the New York Review of Books. He writes a column on international affairs in the Guardian which is widely syndicated in Europe, Asia and the Americas."
            // }
          />
          <TeamMember
            name={"Anne Applebaum"}
            title={"Advisory Council Member"}
            image={team5}
            // text={
            //   "Anne Elizabeth Applebaum is a staff writer for The Atlantic and a Pulitzer-prize winning historian. She has written extensively about the development of civil society in Central and Eastern Europe. Anne's newest book The Twilight of Democracy explains why some of her contemporaries have abandoned liberal democratic ideals in favor of strongman cults, nationalist movements, or one-party states."
            // }
            text={""}
          />
          <TeamMember
            name={"General Wesley K. Clark"}
            title={"Advisory Council Member"}
            image={team10}
            // text={
            //   "General Wesley K. Clark, (ret.) serves as Chairman and CEO of Wesley K. Clark & Associates, a Senior Fellow at UCLA’s Burkle Center for International Relations, Director of the Atlantic Council; and Founding Chair of City Year Little Rock/North Little Rock. Clark retired as a four star general after 38 years in the United States Army, having served as Commander of US Southern Command, Commander of US European Command/ Supreme Allied Commander, Europe. His awards include the Presidential Medal of Freedom, Defense Distinguished Service Medal (five awards), Silver star, bronze star, purple heart, honorary knighthoods from the British and Dutch governments, and numerous other awards from other governments, including award of Commander of the Legion of Honor (France). He has also been awarded the Department of State Distinguished Service Award and numerous honorary doctorates and civilian honors."
            // }
            text={""}
          />
          <TeamMember
            name={"Dr. Francis Fukuyama"}
            title={"Advisory Council Member"}
            image={team12}
            // text={
            //   "Dr. Francis Fukuyama is Olivier Nomellini Senior Fellow at Stanford University's Freeman Spogli Institute for International Studies (FSI), and a faculty member of FSI's Center on Democracy, Development, and the Rule of Law (CDDRL). He is also Director of Stanford's Ford Dorsey Master's in International Policy, and a professor (by courtesy) of Political Science. Dr. Fukuyama has written widely on issues in development and international politics. His 1992 book, The End of History and the Last Man, has appeared in over twenty foreign editions. His most recent book, Identity: The Demand for Dignity and the Politics of Resentment, was published in Sept. 2018. His next book, Liberalism and Its Discontents, will be published in the spring of 2022."
            // }
            text={""}
          />

          <TeamMember
            name={"Zeid Ra'ad Al Hussein"}
            title={"Advisory Council Member"}
            image={team6}
            // text={
            //   "President & CEO of the International Peace Institute. Zeid served as the UN's High Commissioner for Human Rights from 2014 to 2018 after a long career as a Jordanian diplomat, including as his country’s Permanent Representative to the UN (2000-2007 & 2010-2014) and Ambassador to the United States (2007-2010). He served on the UN Security Council, was a configuration chair for the UN Peace-Building Commission, and began his career as a UN Peacekeeper in the former Yugoslavia. He is also a member of The Elders, an independent group of global leaders working together for peace, justice and human rights, founded by Nelson Mandela."
            // }
            text={""}
          />
          <TeamMember
            name={"Professor Charles Gati"}
            title={"Advisory Council Member"}
            image={team11}
            // text={
            //   "Professor Charles Gati, Senior Research Professor of European and Eurasian Studies, Johns Hopkins University's School of Advanced International Studies, Washington, DC, USA."
            // }
            text={""}
          />
          <TeamMember
            name={"Dr. Evelyn Farkas"}
            title={"Advisory Council Member"}
            image={team13}
            // text={
            //   "Dr. Evelyn Farkas is a Nonresident Senior Fellow at the Atlantic Council, and CNA and a National Security Analyst for NBC/MSNBC. She served as Deputy Assistant Secretary of Defense for Russia/Ukraine/Eurasia, responsible for policy towards Russia, the Black Sea, Balkans and Caucasus regions and conventional arms control and as Senior Advisor to the Supreme Allied Commander Europe and Special Advisor to the Secretary of Defense for the NATO Summit. "
            // }
            text={""}
          />
          <TeamMember
            name={"Robert Boorstin"}
            title={"Advisory Council Member"}
            image={team7}
            // text={
            //   "Senior Vice President at ASG and formerly a Director of Public Policy for Google, where he led the company's cross-sectoral effort to protect free expression and privacy on the internet. During the Clinton administration, Mr. Boorstin served in the National Security Council and advised the Secretaries of Treasury and State."
            // }
            text={""}
          />
          <TeamMember
            name={"Eleni Kounalakis"}
            title={"Advisory Council Member"}
            image={team14}
            // text={
            //   "Ambassador Eleni Kounalakis is the 50th Lieutenant Governor of California. From 2010 to 2013, Kounalakis served as President Barack Obama’s Ambassador to the Republic of Hungary. Kounalakis was the first Greek-American woman – and at age 43 one of America’s youngest – to serve as U.S. Ambassador. Her highly acclaimed memoir, “Madam Ambassador, Three Years of Diplomacy, Dinner Parties and Democracy in Budapest” (The New Press, 2015), chronicles the onset of Hungary’s democratic backsliding."
            // }
            text={""}
          />
          <TeamMember
            name={"Timothy Snyder"}
            title={"Advisory Council Member"}
            image={team9}
            // text={
            //   "Timothy Snyder is the Richard C. Levin Professor of History at Yale University and a permanent fellow at the Institute for Human Sciences in Vienna. Snyder's work has appeared in forty languages and has received a number of prizes, including the Emerson Prize in the Humanities, the Literature Award of the American Academy of Arts and Letters, the Václav Havel Foundation prize, the Foundation for Polish Science prize in the social sciences, the Leipzig Award for European Understanding, the Dutch Auschwitz Committee award, and the Hannah Arendt Prize in Political Thought.  Snyder was a Marshall Scholar at Oxford, has received the Carnegie and Guggenheim fellowships, and holds state orders from Estonia, Lithuania, and Poland."
            // }
            text={""}
          />
          <TeamMember
            name={"David Milliband"}
            title={"Advisory Council Member"}
            image={team17}
            // text={
            //   "David Miliband oversees the IRC's humanitarian relief operations in more than 40 war-affected countries and its refugee resettlement and assistance programs in over 20 cities across the United States. Under his leadership, the IRC has expanded its ability to rapidly respond to humanitarian crises and meet the needs of an unprecedented number of people uprooted by conflict, war and disaster."
            // }
            text={""}
          />
          <TeamMember
            name={"Rafał Trzaskowski"}
            title={"Advisory Council Member"}
            image={team16}
            // text={
            //   "He has a Master’s degree from the University of Warsaw, Faculty of Journalism and Political Science in International Economics and Political Relations and a Master’s degree from the European College in European Studies. Trzaskowski gained important national political experience as a Minister of Administration and Digitization (2013-2014) as well as Secretary of State in the Ministry of Foreign Affairs (2014-2015). Rafał Trzaskowski was sworn in as Mayor of Warsaw at the 1st session of Warsaw City Council for the 2018 - 2023 term, after obtaining 56.6% of the popular vote in the elections held on 21st of October."
            // }
            text={""}
          />
          <TeamMember
            name={"Simon Cheng"}
            title={"Advisory Council Member"}
            image={team15}
            // text={
            //   "Simon Cheng Man-kit is a Hong Kong activist. He was formerly a trade and investment officer at the British Consulate-General in Hong Kong. Cheng was detained by Chinese authorities in August 2019 in West Kowloon station when he returned from a business trip in Shenzhen. While the Chinese authorities stated that he was arrested for "soliciting prostitutes", Cheng denied the accusation and added that Chinese agents tortured him in an attempt to make him confess that he was a British spy who was involved in instigating the 2019 Hong Kong protests. Cheng subsequently fled to London and was granted asylum in June 2020."
            // }
            text={""}
          />
        </div>

        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="section-heading">
              <div className="section-icon">
                <IoPeopleSharp />
              </div>
              <h2 className="section__title">Board of Directors</h2>
            </div>
          </div>
        </div>
        <div className="row team-content-wrap justify-content-center">
          <TeamMember
            name={"David Koranyi"}
            title={"Board President and Executive Director"}
            image={team2}
            text={
              "Board President and Executive Director. David served as served as undersecretary of state and chief foreign policy and national security advisor to the Prime Minister of the Republic of Hungary, Gordon Bajnai, senior advisor on city diplomacy to the Mayor of Budapest, Hungary, a non-resident senior fellow at the Atlantic Council, strategic advisor at GLOBSEC, and advisor on sustainable energy at the UN DESA."
            }
            facebook={"https://www.facebook.com/david.koranyi"}
            twitter={"https://twitter.com/DavidKoranyi"}
          />

          <TeamMember
            name={"Chris Maroshegyi"}
            title={"Board Member"}
            image={team3}
            text={
              "Chris is a strategist at Meta Platforms (formerly Facebook). Previously he served as a Senior Director at Albright Stonebridge Group. The son of Hungarian refugees, Chris has been traveling to Hungary since his childhood and was a Fulbright Fellow in Hungary."
            }
            facebook={"https://www.facebook.com/chris.maroshegyi"}
          />
          <TeamMember
            name={"Elizabeth Jennings"}
            title={"Board Member"}
            image={team4}
            text={
              "Elizabeth is a senior-level chief of staff and leader with over 15 years global experience encompassing operational, tactical, and strategic counsel to leaders and key decision makers at the intersection of government, corporate, UN, and non-profit global organizations. She served most recently as Executive Director of Board Affairs and Special Advisor on Global Public Health at the Asia Society"
            }
          />
        </div>
      </div>
    </section>
  );
};

export default TeamArea;
