import React, { useState } from "react";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";
import ServiceArea from "../components/ServiceArea";
import TeamArea from "../components/TeamArea";
import CallToActionTwo from "../components/CallToActionTwo";
import MissionArea from "../components/MissionArea";
import Newsletter from "../components/Newsletter";
import HiwArea from "../components/HiwArea";
import WorkArea from "../components/WorkArea";
import CallToActionThree from "../components/CallToActionThree";
import DonateButton from "../components/DonateButton";
import { useScrollPosition } from "../modules/scrollTracker";

const cta3Slides = [
  {
    title:
      "Democracy doesn't happen by accident. We have to defend it, fight for it, strengthen it, renew it.",
    meta: "PRESIDENT JOSEPH R. BIDEN, JR.",
    date: "February 2021",
  },
  {
    title:
      "We, the Hungarian diaspora, have a special responsibility to make our voices heard.",
    meta: "KATI MARTON",
    date: "January 2022",
  },
  {
    title:
      "The most important election in Europe and perhaps the world this year.",
    meta: "GIDEON RACHMAN",
    date: "Financial Times",
  },
];
const MissionPage = () => {
  const [scroll, setScroll] = useState(0);
  useScrollPosition(function setScrollPosition({ currentPosition }) {
    setScroll(currentPosition.y);
    console.log("scroll: ", scroll);
  });

  return (
    <Layout>
      <NavOne />
      <MissionArea /> {/* Mission, Hungarian Spring */}
      <CallToActionThree slides={cta3Slides} /> {/* Quotes slider*/}
      <ServiceArea /> {/* Values, Objectives */}
      <WorkArea /> {/* Work */}
      {/* <CallToAction /> Activate diaspora, donate */}
      <TeamArea />
      <CallToActionTwo
        title1={"Support Hungarian civil society and independent media"}
        button1={"donate"}
        title2={"Get the latest news about Hungarian Spring"}
        button2={"subscribe newsletter"}
      />
      <HiwArea /> {/* Join our movement */}
      <Newsletter />
      {
        <DonateButton
          link={
            "https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
          }
        />
      }
      <Footer />
    </Layout>
  );
};

export default MissionPage;
