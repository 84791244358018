import React from "react";
import { FaFacebook, FaTwitter } from "react-icons/fa";

const TeamMember = ({
  name,
  title,
  image,
  text,
  facebook,
  twitter,
  offset,
}) => {
  return (
    <div className={`col-lg-3 col-sm-6 ${offset ? "offset-lg-7" : ""}`}>
      <div className="team-item team-item3">
        <div className="team__img">
          <img src={image} alt={name} width={210} heigh={210} />
          <div className="team__img-links">
            <p className="team__img-text">{text}</p>
            <ul>
              {facebook && (
                <li>
                  <a href={facebook}>
                    <FaFacebook />
                  </a>
                </li>
              )}
              {twitter && (
                <li>
                  <a href={twitter}>
                    <FaTwitter />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="team__title">
          <h3 className="team__title-title">{name}</h3>
          <span className="team__title-meta">{title}</span>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
