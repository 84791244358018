import React from "react";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { GiCapitol } from "react-icons/gi";
import { IoCellularSharp, IoPeopleSharp } from "react-icons/io5";
import { BsFillAwardFill } from "react-icons/bs";
import { BiLoaderCircle } from "react-icons/bi";
import { StaticImage } from "gatsby-plugin-image";

const ServiceCol = ({ title, text, children, last }) => (
  <div className={last ? "col-lg-5 mt-3" : "col-lg-3 mt-3"}>
    <div className="service-item service-item1">
      <div className="service-item-inner">
        <div className="service-icon">{children}</div>
        <div className="service-content">
          <h4 className="service__title">{title}</h4>
          <p className="service__desc">{text}</p>
        </div>
      </div>
    </div>
  </div>
);

const ValuesPart = () => (
  <div className="row">
    <div className="col-lg-6">
      <div className="donate-item-img">
        <figure>
          <StaticImage
            height={500}
            src="../images/illu_2_parker-johnson-v0OWc_skg0g-unsplash.jpg"
            alt=""
          />
        </figure>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="donate-item-content">
        <div className="section-heading">
          <div className="section-icon">
            <BsFillAwardFill />
          </div>
          <h2 className="section__title">Values</h2>
          <p className="section__desc">
            We believe in the values of freedom, human dignity, and democratic
            decision-making. We believe democracy works, and works best when
            people can participate in free, fair, and transparent elections.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const ServiceArea = () => {
  return (
    <section className="service-area text-center" id="values">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="donate-item-img">
              <figure>
                <StaticImage
                  height={500}
                  src="../images/illu_2_parker-johnson-v0OWc_skg0g-unsplash.jpg"
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12">
                <div className="donate-item-content">
                  <div className="section-heading">
                    <div className="section-icon">
                      <BsFillAwardFill />
                    </div>
                    <h2 className="section__title">Values</h2>
                    <p className="section__desc">
                      We believe in the values of freedom, human dignity, and
                      democratic decision-making. We believe democracy works,
                      and works best when people can participate in free, fair,
                      and transparent elections.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="section-heading service-heading donate-item-content">
                  <div className="section-icon mt-5">
                    <IoCellularSharp />
                  </div>
                  <h2 className="section__title">Objectives</h2>
                </div>
              </div>
              <div className="col-12">
                <div className="row service-wrap justify-content-center">
                  <ServiceCol
                    title={"Inclusion"}
                    text={"Broaden democratic participation"}
                  >
                    <BiLoaderCircle />
                  </ServiceCol>
                  <ServiceCol
                    title={"Democracy"}
                    text={"Promote free and fair elections"}
                  >
                    <GiCapitol />
                  </ServiceCol>
                  <ServiceCol
                    title={"Rule of law"}
                    text={"Encourage transparent and accountable governance"}
                  >
                    <FaBalanceScaleLeft />
                  </ServiceCol>
                  <ServiceCol
                    title={"Diversity"}
                    text={"Combat hate, fear, and exclusion"}
                  >
                    <IoPeopleSharp />
                  </ServiceCol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceArea;
