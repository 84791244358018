import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { GiFlyingFlag } from "react-icons/gi";
import { IoGlobe } from "react-icons/io5";

const MissionArea = () => {
  return (
    <section className="make-world-area hungarian-spring-area" id="mission">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="section-heading text-center">
              <div className="section-icon">
                <IoGlobe />
              </div>
              <h2 className="section__title">Our Mission</h2>
              {/* <p className="section__meta">
                Empower democratic actors to defend and rebuild their
                democracies
              </p> */}
            </div>
          </div>
        </div>
        <div className="row world-static-wrap2">
          <div className="col-lg-5">
            <div className="world-content">
              <h3 className="world__title">
                We are building an{" "}
                <strong>international pro-democracy solidarity movement</strong>{" "}
                at the grassroots level.
              </h3>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="world-content">
              <p className="world__desc mb-5">
                Action for Democracy is a non-profit, non-partisan organization
                that works to empower democratic actors to defend democracy. We
                are committed to an inclusive and democratic future and we
                mobilize people and campaigns to support this vision.
              </p>
              <table className="world__table">
                <tr>
                  <td>
                    <a href="/mission/#work" className="theme-btn">
                      Our work
                    </a>
                  </td>
                  <td>
                    <a href="/mission/#joinus" className="theme-btn">
                      Get involved
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="row world-static-wrap">
          <div className="col-sm-6 col-md-4">
            <div className="world-item">
              <div className="world-img-box">
                <StaticImage
                  src="../images/crowd2.jpg"
                  width={370}
                  height={365}
                />
              </div>
              <div className="world-img-bg world-img-bg1"></div>
            </div>
          </div>
          <div className="col-md-3 mid-column">
            <div className="world-item">
              <div className="world-img-box">
                <StaticImage src="../images/blm.jpg" width={270} height={365} />
              </div>
              <div className="world-img-bg world-img-bg2"></div>
            </div>
          </div>
          <div className="col-sm-6 col-md-5">
            <div className="world-item">
              <div className="world-img-box">
                <StaticImage
                  src="../images/media.jpg"
                  width={470}
                  height={365}
                />
              </div>
              <div className="world-img-bg world-img-bg3"></div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="section-heading text-center">
              <div className="section-icon">
                <GiFlyingFlag />
              </div>
              <h2 className="section__title">Hungarian Spring</h2>
              <p className="section__meta">
                Help Hungary's democratic actors to save Hungarian democracy
              </p>
            </div>
          </div>
        </div> */}

        {/* <div className="row world-static-wrap2">
          <div className="col-lg-5">
            <div className="world-content">
              <h3 className="world__title">
                Hungary is a <strong>battleground state</strong> in the global
                fight to defend democracy.
              </h3>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="world-content">
              <p className="world__desc">
                Democracy is under siege. The next battleground is in Hungary
                where anti-democratic forces are trying to use an upcoming
                election in April to cement their grip over one of Europe's most
                vibrant countries. The sitting government{" "}
                <a
                  href="https://freedomhouse.org/country/hungary"
                  alt="Freedom House report"
                  target="_blank"
                  rel="noreferrer"
                >
                  has systematically dismantled
                </a>{" "}
                checks and balances, eroded democratic values, and instilled a
                culture of hate against immigrants and the LGBTQ community. The
                government has inspired like-minded autocrats across Europe and
                the United States, from the likes of Donald Trump to Tucker
                Carlson, and Steven Bannon.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default MissionArea;
