import React from "react";
import { GiCapitol } from "react-icons/gi";
import { FaMoneyBillAlt } from "react-icons/fa";
import { BsMegaphoneFill } from "react-icons/bs";

const WorkCol = ({ title, text, children, last }) => (
  <div className="col-12 col-md-6 col-lg-4 mb-5">
    <div className="service-item service-item1">
      <div className="service-item-inner text-center">
        <div className="service-icon">{children}</div>
        <h4 className="service__title mt-3 mb-5">{title}</h4>

        <p className="service__desc margin-auto">{text}</p>
      </div>
    </div>
  </div>
);

const WorkArea = () => {
  return (
    <section className="make-world-area work-area" id="work">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="section-heading text-center">
              <h2 className="section__title">Our Work</h2>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-8 mx-auto">
            <div className="word-content text-center section-heading">
              <h3 className="section__meta">
                Action for Democracy works to empower people to participate in
                their democracies
              </h3>
            </div>
          </div>
        </div>

        <div className="row world-static-wrap2 justify-content-center">
          <>
            <WorkCol
              title={"Campaigns"}
              text={`We organize and enable citizens to participate in government and electoral processes, with a focus on ensuring diaspora communities can make their voices heard in their native countries.`}
            >
              <BsMegaphoneFill />
            </WorkCol>
            <WorkCol
              title={"Education"}
              text={`We promote transparency by reporting on the state of democracy and connecting citizens to independent journalists.`}
            >
              <GiCapitol />
            </WorkCol>
            <WorkCol
              title={"Funding"}
              text={`We fund movements on the frontlines of defending democracy, with
                a focus on groups that promote: independent journalism, civic
                participation, and anti-corruption efforts.`}
            >
              <FaMoneyBillAlt />
            </WorkCol>
          </>
        </div>
      </div>
    </section>
  );
};

export default WorkArea;
