import React from "react";

const CallToActionTwo = ({ title1, button1, title2, button2 }) => {
  return (
    <section className="cta-area text-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="cta-item cta-item1">
              <h3 className="cta__title">{title1}</h3>
              <a
                href="https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
                target="_blank"
                rel="noopener noreferrer"
                className="theme-btn theme-btn-2"
              >
                {button1}
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="cta-item cta-item2">
              <h3 className="cta__title">{title2}</h3>
              <a href="/mission/#newsletter" className="theme-btn theme-btn-2">
                {button2}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToActionTwo;
